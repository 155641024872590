import React from "react";

import LoadingBox from "./components/boxs/LoadingBox";

const WebApp = React.lazy(() => import("./components/app/WebApp"));

const App = () => {
  return (
    <React.Suspense fallback={<LoadingBox />}>
      <WebApp />
    </React.Suspense>
  );
};
export default App;
