import { registerRootComponent } from "expo";
import { disableReactDevTools } from "@fvilers/disable-react-devtools";

import App from "./App";

if (process.env.NODE_ENV === "production") {
  disableReactDevTools();
}

registerRootComponent(App);
